<button
    class="menu__button"
    type="button"
    [ngClass]="{'menu__button_active': isActive, 'menu__button_inactive': !isActive}"
    (click)="onClick()">
    <ng-container *ngIf="loggedIn">
        <span class="menu__media">
            <img class="menu__img"
                *ngIf="!isActive"
                @navIcon
                [src]="MenuIcons.Search"
                [alt]="'mobile_nav|search' | localization"/>
            <img class="menu__img"
                *ngIf="isActive"
                @navActiveIcon
                [src]="MenuIcons.SearchActive"
                [alt]="'mobile_nav|search' | localization"/>
        </span>
        <span class="menu__text">
            {{ "mobile_nav|search" | localization }}
        </span>
    </ng-container>
    <ng-container *ngIf="!loggedIn">
        <span class="menu__media">
            <img class="menu__img"
                *ngIf="!isActive"
                @navResumesIcon
                [src]="MenuIcons.Providers"
                [alt]="'mobile_nav|vacancies' | localization"/>
            <img class="menu__img"
                *ngIf="isActive"
                @navActiveResumesIcon
                [src]="MenuIcons.ProvidersActive"
                [alt]="'mobile_nav|resumes' | localization"/>
        </span>
        <span class="menu__text">
            {{ "mobile_nav|resumes" | localization }}
        </span>
    </ng-container>
</button>
