import {InjectionToken} from '@angular/core';

interface ApplicationLeveledLogMethod {
    (message: string): void;

    (message: string, meta: any): void;

    (message: string, ...meta: any[]): void;

    (infoObject: object): void;
}

export interface ApplicationLogger {
    error: ApplicationLeveledLogMethod;
}

export const APPLICATION_LOGGER: InjectionToken<ApplicationLogger> = new InjectionToken('ApplicationLogger');
