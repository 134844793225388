import {APP_INITIALIZER, makeEnvironmentProviders} from "@angular/core";
import {PwaInstallService} from "./pwa-install.service";

function pwaInstallInitFactory(pwaInstallService: PwaInstallService) {
    return () => pwaInstallService.init();
}

export function providePwaInstallService() {
    return makeEnvironmentProviders([{
        provide: APP_INITIALIZER,
        useFactory: pwaInstallInitFactory,
        deps: [PwaInstallService],
        multi: true,
    }]);
}
