import {ValueLanguage} from './localization-api/localization-api-client';
import {COUNTRY_DOMAIN} from '../app-config/AppConfig';

export const AppLocales: { [domain in keyof typeof COUNTRY_DOMAIN]: { [lang in keyof typeof ValueLanguage]?: string } } = {
    RU: {
        Ru: 'ru',
        En: 'en',
    },
    CA: {
        En: 'en-CA',
        Fr: 'fr-CA',
        He: 'he-IL',
        Trl: 'en-CA',
    },
    IL: {
        He: 'he-IL',
        En: 'en',
    },
    UK: {
        EnUk: 'en-GB',
    },
    US: {
        EnUs: 'en-US',
    },
};
