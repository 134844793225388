import {NotificationViewModel} from './notification-view-model';

export class NotificationsStateViewModel {
    public readonly isLoading: boolean;
    public readonly notifications: NotificationViewModel[];
    public readonly isEmpty: boolean;

    constructor(notifications: NotificationViewModel[], isLoading: boolean) {
        this.notifications = notifications ?? [];
        this.isEmpty = !(this.notifications.length > 0);
        this.isLoading = isLoading;
    }
}
