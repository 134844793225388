import {ApplicationConfig, mergeApplicationConfig} from '@angular/core';
import {appConfig} from './app.config';
import {provideLottieOptions} from "ngx-lottie";
import {lottiePlayerFactory} from "./infrastructure/lottie/playerFactory";
import {provideServiceWorkerBypass} from "./infrastructure/api/sw-bypass/providers";
import {provideAudioRecording} from "./infrastructure/audio-recording/recorder/provideAudioRecording";
import {provideGoogleTagManagerScripts} from "./infrastructure/google-tag-manager/google-tag-manager.init";
import {provideAudioCompression} from "./infrastructure/audio-compression/audio-compression-service-impl.service";
import {provideScrollPositionRestoration} from "./infrastructure/scrolling/providers";
import {provideServiceWorkerFeatures} from "./infrastructure/service-worker/provideServiceWorkerFeatures";
import {provideServiceWorkerCustom} from "./infrastructure/service-worker/provideServiceWorkerCustom";

const browserConfig: ApplicationConfig = {
    providers: [
        provideGoogleTagManagerScripts(),
        provideServiceWorkerBypass(),
        provideAudioRecording(),
        provideServiceWorkerFeatures(),
        provideLottieOptions({player: lottiePlayerFactory}),
        provideAudioCompression(),
        provideScrollPositionRestoration(),
        provideServiceWorkerCustom(),
    ]
};

export const config = mergeApplicationConfig(appConfig, browserConfig);
