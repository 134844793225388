var common = require('./common.js');
var System = common.System;
var VbrMode = common.VbrMode;
var Float = common.Float;
var ShortBlock = common.ShortBlock;
var Util = common.Util;
var Arrays = common.Arrays;
var new_array_n = common.new_array_n;
var new_byte = common.new_byte;
var new_double = common.new_double;
var new_float = common.new_float;
var new_float_n = common.new_float_n;
var new_int = common.new_int;
var new_int_n = common.new_int_n;
var assert = common.assert;
var GrInfo = require('./GrInfo.js');
function IIISideInfo() {
  this.tt = [[null, null], [null, null]];
  this.main_data_begin = 0;
  this.private_bits = 0;
  this.resvDrain_pre = 0;
  this.resvDrain_post = 0;
  this.scfsi = [new_int(4), new_int(4)];
  for (var gr = 0; gr < 2; gr++) {
    for (var ch = 0; ch < 2; ch++) {
      this.tt[gr][ch] = new GrInfo();
    }
  }
}
module.exports = IIISideInfo;