import {ChangeDetectionStrategy, Component} from '@angular/core';
import {LocalizationPipe} from "../../../infrastructure/localization/localization.pipe";
import {RouterLink} from "@angular/router";
import {ScreenModeDirective} from "../../../infrastructure/screen-mode/screen-mode.directive";

@Component({
    selector: 'nn-worker-interesting',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LocalizationPipe,
        RouterLink,
        ScreenModeDirective
    ]
})
export class WorkerInterestingComponent {}
