import {Injectable} from "@angular/core";
import {COUNTRY_DOMAIN} from "./app-config/AppConfig";

@Injectable({
    providedIn: 'root',
})
export class AppStoreLinkProvider {
    public getLink(countryDomain: COUNTRY_DOMAIN): string {
        return LINKS[countryDomain];
    }
}

const LINKS: { [countryDomain in COUNTRY_DOMAIN]: string } = {
    RU: 'https://apps.apple.com/us/app/наша-няня/id1536024102',
    CA: 'https://apps.apple.com/ca/app/nannyservices-ca/id1536024102',
    IL: 'https://apps.apple.com/il/app/id1536024102',
    UK: 'https://apps.apple.com/gb/app/mumshelpers-co-uk/id1536024102',
    US: null,
};
