import {inject, Inject, Injectable, OnDestroy} from '@angular/core';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {BehaviorSubject, combineLatest, from, Observable, of, Subject} from 'rxjs';
import {ComponentPortal} from '@angular/cdk/portal';
import {IdentityService} from '../../../core/users/core/identity.service';
import {debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {WINDOW_TOKEN} from '../../../infrastructure/dom/WINDOW_TOKEN';
import {ScreenSizeService} from '../../../infrastructure/responsive-screen-size-detector/screen-size.service';
import {ScreenModeDirective} from '../../../infrastructure/screen-mode/screen-mode.directive';
import {InterviewGoogleFormUrlService} from './interview-google-form-url.service';
import {UserProfileFacade} from '../../../core/users/core/user-profile.facade';
import moment from 'moment';
import {AppTimersService} from "../../../infrastructure/timers/app-timers.service";

type PositionMode = 'desktop' | 'mobile';

@Injectable({
    providedIn: 'root',
})
export class InterviewBannerService implements OnDestroy {
    private readonly appTimersService = inject(AppTimersService);
    private static registrationDelayInDays = 4;
    private static initialDelayInMs = 10000;
    private readonly destroySubject$: Subject<void> = new Subject<void>();
    private readonly actionSubject$: Subject<'pass' | 'hide'> = new Subject<'pass' | 'hide'>();
    private readonly refreshSubject$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    private readonly storage: Storage;

    private overlayRef: OverlayRef;

    constructor(
        private readonly overlay: Overlay,
        private readonly identityService: IdentityService,
        private readonly screenSizeService: ScreenSizeService,
        private readonly userProfileFacade: UserProfileFacade,
        @Inject(WINDOW_TOKEN) private readonly window: Window,
        private readonly formUrlService: InterviewGoogleFormUrlService,
    ) {
        this.storage = window?.localStorage;
    }

    public init(): void {
        this.appTimersService.getTimer({dueTime: InterviewBannerService.initialDelayInMs, observeOnNgZone: true}).pipe(
            switchMap(() => this.refreshSubject$),
            switchMap(() => this.identityService.stateChanges.pipe(
                    map(authState => authState.user?.Id),
                    distinctUntilChanged(),
                ),
            ),
            switchMap((userId) => {
                if (!!userId && !this.storage.getItem(`${userId}-interview-banner`))
                    return this.userProfileFacade.getProfile().pipe(map(profile => {
                        const now = moment();
                        const regDate = moment(profile.registrationDateTime);
                        const diffInDays = Math.abs(now.diff(regDate, 'days'));
                        return (diffInDays > InterviewBannerService.registrationDelayInDays);
                    }));
                return of(false);
            }),
            distinctUntilChanged(),
            switchMap((show) => show ? this.showOverlay() : this.hideOverlay()),
            takeUntil(this.destroySubject$),
        ).subscribe();

        this.actionSubject$.pipe(
            debounceTime(250),
            switchMap((action) => {
                if (action === 'pass') return this.openForm();
                return of(null);
            }),
            switchMap(() => this.getUserId()),
            tap((userId) => this.storage.setItem(`${userId}-interview-banner`, 'hidden')),
            tap(() => this.refreshSubject$.next()),
            takeUntil(this.destroySubject$),
        ).subscribe();
    }

    public pass(): void {
        this.actionSubject$.next('pass');
    }

    public dismiss(): void {
        this.actionSubject$.next('hide');
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    private getUserId(): Observable<string> {
        return this.identityService.stateChanges.pipe(
            take(1),
            map((authState) => authState.user?.Id),
        );
    }

    private openForm(): Observable<void> {
        return this.formUrlService.getUrl().pipe(
            tap(url => this.window.open(url, '_blank')),
            map(() => null),
        );
    }

    private showOverlay(): Observable<void> {
        const positionMode$ = this.screenSizeService.size$.pipe(
            map((size) => ScreenModeDirective.getScreenSizesForScreenMode('DESKTOP').indexOf(size) > -1),
            map((isDesktop) => (isDesktop ? 'desktop' : 'mobile') as PositionMode),
            distinctUntilChanged(),
            shareReplay({bufferSize: 1, refCount: true}),
        );

        return combineLatest(from(import('./component')), positionMode$).pipe(
            tap(([{InterviewBannerComponent}, positionMode]) => this.makeOverlayRef(InterviewBannerComponent, positionMode)),
            map(() => null),
        );
    }

    private makeOverlayRef(component: ComponentType<unknown>, positionMode: PositionMode): void {
        let position = this.overlay.position().global();

        position = positionMode === 'desktop'
            ? position.right('48px').bottom('48px')
            : position.centerHorizontally().bottom('120px');

        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.updatePositionStrategy(position);
            return;
        }

        this.overlayRef = this.overlay.create({
            hasBackdrop: false,
            positionStrategy: position,
        });

        const portal = new ComponentPortal(component);
        this.overlayRef.attach(portal);
    }

    private hideOverlay(): Observable<void> {
        this.overlayRef?.dispose();
        return of(null);
    }
}
