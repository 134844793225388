import {Component, OnDestroy} from '@angular/core';
import {ConnectionStatusService} from '../../infrastructure/connection-status/connection-status.service';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {OfflineOverlayComponent} from './offline-overlay/component';
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
    selector: 'nn-layout-offline-message',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf
    ]
})
export class OfflineMessageComponent implements OnDestroy {
    public status$: Observable<('online' | 'offline')>;
    public overlayRef: OverlayRef;
    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(connectionStatusService: ConnectionStatusService, private overlay: Overlay) {
        this.status$ = connectionStatusService.status$
            .pipe(map(status => status.hasNetworkConnection && status.hasInternetAccess ?
                'online' : 'offline'));
        this.status$.pipe(takeUntil(this.destroySubject$))
            .subscribe(status => {
                if (status === 'offline') this.showOverlay();
                else this.hideOverlay();
            });
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    private showOverlay() {
        if (!this.overlayRef)
            this.overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true,
                panelClass: 'cdk-overlay-container_nointernet',
            });
        if (!this.overlayRef.hasAttached())
            this.overlayRef.attach(new ComponentPortal(OfflineOverlayComponent));
    }

    private hideOverlay() {
        if (this.overlayRef)
            this.overlayRef.detach();
    }
}
