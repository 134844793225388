import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export const MOB_NAV_SLIDE_UPDOWN_ANIMATION = trigger('navSlideUpDown', [
    transition(':enter', [
        style({
            transform: 'translateY(100%)',
            backgroundColor: 'rgba(0, 0, 0, 0)',
        }),
        animate('320ms ease-in', keyframes([
            style({
                transform: 'translateY(0%)',
                offset: 0.8,
            }),
            style({
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                offset: 1.0,
            }),
        ])),
    ]),
    transition(':leave', [
        animate('320ms ease-in', keyframes([
            style({
                backgroundColor: 'rgba(0, 0, 0, 0)',
                offset: 0.2,
            }),
            style({
                transform: 'translateY(100%)',
                offset: 1.0,
            }),
        ])),
    ]),
]);
