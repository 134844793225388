import {Observable} from 'rxjs';
import {inject, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {REQUEST} from "../../../express.tokens";
import {isPlatformServer} from "@angular/common";
import {BffEndpoints} from "../../core/users/core/session/bff-endpoints";
import {APP_CONFIG} from "../app-config/AppConfig";

const headersToCopy = [
    'x-forwarded-for',
    'x-forwarded-proto',
    'x-forwarded-port',
    'x-forwarded-host',
    'x-real-ip',
] as const;

export const SsrAuthHttpInterceptorFn: HttpInterceptorFn =
    (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
        const platformId = inject(PLATFORM_ID);
        const bffEndpoints = inject(BffEndpoints);
        if (isPlatformServer(platformId) &&
            (req.url === bffEndpoints.tokenEndpoint || req.url === bffEndpoints.refreshTokenEndpoint)) {
            const debugBffCookie = inject(APP_CONFIG).debugBffCookie;
            if (debugBffCookie) {
                req = req.clone(
                    {
                        withCredentials: true,
                        setHeaders: {
                            cookie: `.AspNetCore.BffCookie=${debugBffCookie};`
                        },
                    }
                );
                return next(req);
            }

            const request = inject(REQUEST, {optional: true});
            if (request) {
                const headers = { cookie: request.headers.cookie }
                for (const header of headersToCopy) {
                    const requestHeader = request.header(header);
                    if (requestHeader) headers[header] = requestHeader;
                }
                req = req.clone(
                    {
                        withCredentials: true,
                        setHeaders: headers,
                    }
                );
            }
        }
        return next(req);
    }
