'use strict';

var $TypeError = require('es-errors/type');

/** @type {import('./RequireObjectCoercible')} */
module.exports = function RequireObjectCoercible(value) {
  if (value == null) {
    throw new $TypeError(arguments.length > 0 && arguments[1] || 'Cannot call method on ' + value);
  }
  return value;
};