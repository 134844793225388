import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {BehaviorSubject, from, merge, Observable, of, Subject, throwError, timer} from 'rxjs';
import {observeOnZone} from '../../../../modules/rx-js-custom-operators/observeOnZone';
import {AudioRecorder} from './AudioRecorder';
import {
    catchError,
    distinctUntilChanged,
    exhaustMap,
    finalize,
    first,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import {rtcConfig} from './RTC.config';
import {switchMapCombine} from "../../../shared/misc/operators";

export type AudioPermissionState = PermissionState | 'unknown';

function stopTracks(stream: MediaStream) {
    stream.getAudioTracks().forEach((track) => track.stop());
}

@Injectable()
export class AudioRecordingService implements AudioRecorder, OnDestroy {
    private readonly internalPermissionState$: Observable<AudioPermissionState>;
    private readonly permissionStateSubject$ = new BehaviorSubject<AudioPermissionState>('unknown');
    private readonly requestPermissionsSubject$ = new Subject<void>();
    private readonly destroySubject$: Subject<void> = new Subject<void>();

    constructor(private readonly ngZone: NgZone) {
        this.internalPermissionState$ = this.permissionStateSubject$.pipe(
            distinctUntilChanged(),
            switchMap(() => this.checkPermission()),
            shareReplay({bufferSize: 1, refCount: true}));
        this.requestPermissionsSubject$.pipe(
            exhaustMap(() => this.requestPermissionsInternal()),
            takeUntil(this.destroySubject$),
        ).subscribe();
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public get permissionState$(): Observable<AudioPermissionState> {
        return this.internalPermissionState$;
    }

    public checkPermission(): Observable<AudioPermissionState> {
        if (!navigator?.permissions) return of('unknown');
        try {
            const request = navigator.permissions.query({name: 'microphone'} as unknown as PermissionDescriptor);
            return from(request)
                .pipe(
                    map(result => result.state),
                    catchError(() => of('unknown' as const)),
                );
        } catch (e) {
            return of('unknown');
        }
    }

    public requestPermissions() {
        this.requestPermissionsSubject$.next();
    }

    private requestPermissionsInternal(): Observable<void> {
        if (!navigator.mediaDevices) {
            console.log('\'navigator.mediaDevices\' is not supported. Unable to record audio');
            this.permissionStateSubject$.next('denied');
            return of();
        }
        return this.getMediaStream()
            .pipe(
                tap(stream => stopTracks(stream)),
                switchMap(() => this.checkPermission()),
                catchError(() => of('denied' as const)),
                tap(state => this.permissionStateSubject$.next(state)),
                map(() => null),
            );
    }

    private getMediaStream(): Observable<MediaStream> {
        if (!navigator.mediaDevices) {
            return throwError('navigator.mediaDevices is not supported. Unable to record audio');
        }
        return from(navigator.mediaDevices.getUserMedia({audio: true}));
    }

    public startRecording(stopSignal$: Observable<void>, maxLength: number): Observable<string> {
        return from(import ('recordrtc')).pipe(
            switchMapCombine(() => this.getMediaStream()),
            switchMap(([RecordRTC, stream]) => {
                const recorder = new RecordRTC.default(stream, rtcConfig);
                recorder.startRecording();
                return merge(timer(maxLength), stopSignal$)
                    .pipe(
                        first(),
                        switchMap(() =>
                            new Observable<string>(subscriber => {
                                // @ts-ignore
                                recorder.stopRecording((objectUrl) => {
                                    subscriber.next(objectUrl);
                                    subscriber.complete();
                                });
                            }),
                        ),
                        finalize(() => stopTracks(stream)),
                    );
            }),
            observeOnZone(this.ngZone),
        );
    }
}
