import {Inject, Injectable, Optional} from '@angular/core';
import {MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';

type SupportedLocales = 'ru' | 'en' | 'fr' | 'he';

const weekDays: Record<SupportedLocales, string[]> = {
    ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    fr: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    he: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
};

const firstDayOfWeek: Record<SupportedLocales, number> = {
    ru: 1,
    en: 1,
    fr: 1,
    he: 0,
};

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    private readonly supportedLocale: SupportedLocales;

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string) {
        super();
        this.supportedLocale = matDateLocale?.substring(0, 2).toLowerCase() as SupportedLocales;
    }

    override getDayOfWeekNames() {
        return weekDays[this.supportedLocale];
    }

    override getFirstDayOfWeek(): number {
        return firstDayOfWeek[this.supportedLocale];
    }
}
