import {inject, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {AppTimersService} from '../timers/app-timers.service';

@Injectable({providedIn: 'root'})
export class ServiceWorkerCheckForUpdatesService {
    private static readonly CHECK_INTERVAL = 60 * 60 * 1000;
    private readonly appTimers = inject(AppTimersService);
    private readonly updates = inject(SwUpdate);

    public init() {
        const interval$ = this.appTimers.getTimer({
            dueTime: 0,
            intervalDuration: ServiceWorkerCheckForUpdatesService.CHECK_INTERVAL
        });
        interval$.subscribe(() => this.updates.isEnabled && this.updates.checkForUpdate());
    }
}
