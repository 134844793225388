import {Injectable} from "@angular/core";
import {User} from "../../../infrastructure/auth/models/user";
import {MobileMenuItem} from "../misc/mobileMenuItem";

@Injectable({providedIn: "root"})
export class MobileNavItemsService{
    public getMenuItemsForUser(user: User): MobileMenuItem[] {
        const items: MobileMenuItem[] = [];
        items.push(user && user.isEmployer ? MobileMenuItem.ResumeSearch : MobileMenuItem.VacancySearch);
        items.push(user && (user.isEmployer ? MobileMenuItem.Favorites : MobileMenuItem.ResumeManagement)
            || MobileMenuItem.ResumeSearch);
        items.push(user ? MobileMenuItem.Chat : MobileMenuItem.Login);
        items.push(user ? MobileMenuItem.Account : MobileMenuItem.Register);
        return items;
    }
}
