import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {LocalizationPipe} from "../../../../../../infrastructure/localization/localization.pipe";
import {NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MobileMenuItem} from "../../../../misc/mobileMenuItem";
import {MenuIcons} from "../menu-icons";
import {MOB_NAV_ACTIVE_ICON_ANIMATION, MOB_NAV_ICON_ANIMATION} from "../../../../animations/mob-nav-icon.animation";

@Component({
    selector: "nn-regular-menu-item",
    templateUrl: "component.html",
    styleUrls: ["component.sass"],
    animations: [
        MOB_NAV_ICON_ANIMATION,
        MOB_NAV_ACTIVE_ICON_ANIMATION,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LocalizationPipe,
        NgIf,
        RouterLink,
        RouterLinkActive,
    ],
})
export class RegularMenuItemComponent {
    @Input({required: true}) isActive: boolean;
    @Input({required: true}) item: keyof typeof MobileMenuItem;
    @Input({required: true}) title: string;
    @Input({required: true}) icon: MenuIcons;
    @Input({required: true}) highlightedIcon: MenuIcons;
    @Input({required: true}) link: string;
}
