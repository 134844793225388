import {Observable} from 'rxjs';
import {inject} from '@angular/core';
import {HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {JwtService} from './jwt.service';
import {mergeMap} from 'rxjs/operators';
import {JwtInterceptorOptions} from "./jwtInterceptorOptions";

export const JwtInterceptorFn: HttpInterceptorFn =
    (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
        const options = req.context.get(JwtInterceptorOptions);
        if (options.skip) return next(req);
        return inject(JwtService).addAuthHeader(req).pipe(
            mergeMap((updatedRequest) => next(updatedRequest)),
        );
    }
