import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {MOB_NAV_SLIDE_UPDOWN_ANIMATION} from "../../animations/mob-nav-slide-updown.animation";
import {MobileMenuItem} from "../../misc/mobileMenuItem";
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {ResumeSearchNavigationPanelComponent} from "./panels/resume-search-navigation-panel/component";
import {VacancySearchNavigationPanelComponent} from "./panels/vacancy-search-navigation-panel/component";
import {ProfileNavigationPanelComponent} from "./panels/profile-navigation-panel/component";
import {NnActiveMobileMenuPipe} from "../../pipes/active-menu.pipe";

@Component({
    selector: "nn-mobile-nav-slide-panel",
    templateUrl: "component.html",
    styleUrls: ["component.sass"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [MOB_NAV_SLIDE_UPDOWN_ANIMATION],
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        NgClass,
        ResumeSearchNavigationPanelComponent,
        VacancySearchNavigationPanelComponent,
        ProfileNavigationPanelComponent,
        NnActiveMobileMenuPipe
    ]
})
export class MenuSlidePanelComponent {
    protected readonly Menu = MobileMenuItem;

    @Input({required: true}) activatedPanel: MobileMenuItem;
}
