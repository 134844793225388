import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export const MOB_NAV_VACANCIES_ICON_ANIMATION = trigger('navVacanciesIcon', [
    transition(':enter', [
        style({
            transform: 'translateY(-20%) rotateX(90deg) scale(1.2)',
            position: 'absolute',
        }),
        animate('320ms ease-in-out', style({
            transform: 'translateY(0%) rotateX(0deg) scale(1.0)',
        })),
    ]),
    transition(':leave', [
        style({
            position: 'absolute',
        }),
        animate('320ms ease-in-out', style({
            transform: 'translateY(20%) rotateX(-90deg) scale(1.2)',
        })),
    ]),
]);

export const MOB_NAV_ACTIVE_VACANCIES_ICON_ANIMATION = trigger('navActiveVacanciesIcon', [
    transition(':enter', [
        style({
            transform: 'translateY(-20%) rotateX(90deg) scale(0.8)',
            position: 'absolute',
        }),
        animate('500ms ease-in-out', keyframes([
            style({
                transform: 'translateY(0%) rotateX(0deg) scale(1.0)',
                offset: 0.64,
            }),
            style({
                transform: 'rotate(4deg)',
                offset: 0.73,
            }),
            style({
                transform: 'rotate(-4deg)',
                offset: 0.82,
            }),
            style({
                transform: 'rotate(4deg)',
                offset: 0.91,
            }),
            style({
                transform: 'rotate(0deg)',
                offset: 1.0,
            }),
        ])),
    ]),
    transition(':leave', [
        style({
            position: 'absolute',
        }),
        animate('320ms ease-in-out', style({
            transform: 'translateY(20%) rotateX(-90deg) scale(0.8)',
        })),
    ]),
]);
