import {Injector, makeEnvironmentProviders} from '@angular/core';
import {GeoAutocompleteService} from './abstract/geo-autocomplete.service';
import {GeolocationService} from './abstract/geolocation.service';
import {DadataGeoAutocompleteService} from '../dadata/dadata-geo-autocomplete.service';
import {DadataGeolocationService} from '../dadata/dadata-geolocation.service';
import {APP_CONFIG, AppConfig} from '../../../../infrastructure/app-config/AppConfig';
import {PostalCodeGeolocationService} from '../postal-codes/postal-code-geolocation.service';
import {PeliasGeolocationService} from '../pelias/pelias-geolocation.service';
import {PeliasGeoAutocompleteService} from '../pelias/pelias-geo-autocomplete.service';
import {PostalCodeAutocompleteService} from '../postal-codes/postal-code-autocomplete.service';
import {GeoapifyGeolocationService} from "../us-geoapify";

function geoLocationServiceFactory(appConfig: AppConfig, injector: Injector): GeolocationService {
    if (appConfig.isCountryDomain('US')) {
        return injector.get(GeoapifyGeolocationService);
    }
    if (appConfig.isAddressPostalOriented()) {
        return injector.get(PostalCodeGeolocationService);
    }
    if (appConfig.isCountryDomain('RU')) {
        return injector.get(DadataGeolocationService);
    }
    if (appConfig.isCountryDomain('IL')) {
        return injector.get(PeliasGeolocationService);
    }
    return undefined;
}

function geoAutoCompleteServiceFactory(appConfig: AppConfig, injector: Injector): GeoAutocompleteService {
    if (appConfig.isAddressPostalOriented()) {
        return injector.get(PostalCodeAutocompleteService);
    }
    if (appConfig.isCountryDomain('RU')) {
        return injector.get(DadataGeoAutocompleteService);
    }
    if (appConfig.isCountryDomain('IL')) {
        return injector.get(PeliasGeoAutocompleteService);
    }
    return undefined;
}

export function provideGeolocationServices() {
    return makeEnvironmentProviders([
        {
            provide: GeolocationService,
            useFactory: geoLocationServiceFactory,
            deps: [APP_CONFIG, Injector],
        },
        {
            provide: GeoAutocompleteService,
            useFactory: geoAutoCompleteServiceFactory,
            deps: [APP_CONFIG, Injector],
        },
    ]);
}
