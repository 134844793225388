import {Inject, Injectable, RendererFactory2} from '@angular/core';
import {tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {LanguageService} from '../localization/language.service';

@Injectable({
    providedIn: 'root',
})
export class TextDirectionService {
    constructor(
        private readonly languageService: LanguageService,
        private readonly rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private readonly document: Document) {}

    public init() {
        const renderer = this.rendererFactory.createRenderer(null, null);
        this.languageService.textDirection$.pipe(
            tap(direction => renderer.setAttribute(this.document.body, 'dir', direction)),
        ).subscribe();
    }
}
