import {ApplicationRef, inject, Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {WINDOW_TOKEN} from '../dom/WINDOW_TOKEN';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject, from, Observable, of, Subject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';

@Injectable({providedIn: 'root'})
export class AndroidBannerService implements OnDestroy {
    private static readonly KEY: string = 'android-banner';

    private readonly appRef = inject(ApplicationRef);

    private readonly storage: Storage;
    private readonly destroySubject$: Subject<void> = new Subject<void>();
    private readonly refreshSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    private overlayRef: OverlayRef;

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        @Inject(WINDOW_TOKEN) private readonly window: Window,
        private readonly overlay: Overlay,
    ) {
        if (!isPlatformBrowser(platformId) || !window)
            return;
        this.storage = window.localStorage;
    }

    private get dismissed(): boolean {
        const data = this.storage.getItem(AndroidBannerService.KEY);
        return !!data;
    }

    private get isAndroidWeb(): boolean {
        if (!this.window) return false;
        let isBrowser = true;
        const mqStandAlone = '(display-mode: standalone)';
        if ((this.window.navigator as any).standalone || this.window.matchMedia(mqStandAlone).matches) {
            isBrowser = false;
        }
        const userAgent = this.window.navigator.userAgent.toLowerCase();
        const isAndroid = userAgent.indexOf('android') > -1;
        return isBrowser && isAndroid;
    }

    public init() {
        this.appRef.isStable.pipe(
            filter(isStable => isStable),
            take(1),
            switchMap(() => this.refreshSubject),
            filter(() => this.isAndroidWeb),
            switchMap(() => this.dismissed ? this.hideOverlay() : this.showOverlay()),
            takeUntil(this.destroySubject$),
        ).subscribe();
    }

    public dismiss(): void {
        if (!this.storage) return;
        this.storage.setItem(AndroidBannerService.KEY, 'dismissed');
        this.refreshSubject.next();
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    private showOverlay(): Observable<void> {
        return from(import('./component')).pipe(
            tap(({AndroidBannerComponent}) => this.makeOverlayRef(AndroidBannerComponent)),
            map(() => null),
        );
    }

    private hideOverlay(): Observable<void> {
        this.overlayRef?.dispose();
        return of(null);
    }

    private makeOverlayRef(component: ComponentType<unknown>): void {
        this.overlayRef = this.overlay.create({
            hasBackdrop: false,
            height: 106,
            positionStrategy: this.overlay.position().global().centerHorizontally(),
        });

        const portal = new ComponentPortal(component);
        this.overlayRef.attach(portal);
    }
}
