import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CanActivate, CanActivateChild, CanLoad, Router, UrlTree} from '@angular/router';
import {IdentityService} from '../../../core/users/core/identity.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DeactivateGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private readonly router: Router,
                private readonly identityService: IdentityService,
                @Inject(PLATFORM_ID) private readonly platformId: object) {
    }

    public canActivate(): Observable<true | UrlTree> {
        return this.checkAccessRights();
    }

    public canLoad(): Observable<true | UrlTree> {
        return this.canActivate();
    }

    public canActivateChild(): Observable<true | UrlTree> {
        return this.canActivate();
    }

    private checkAccessRights(): Observable<true | UrlTree> {
        const user$ = this.identityService.stateChanges.pipe(take(1), map(authState => authState.user));
        return user$
            .pipe(
                map(user => !user?.isLocked || this.router.createUrlTree(['/deactivated-user'])),
            );
    }
}
