import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export const MOB_NAV_ICON_ANIMATION = trigger('navIcon', [
    transition(':enter', [
        style({
            opacity: 0,
            position: 'absolute',
        }),
        animate('320ms ease-in-out', style({
            opacity: 1,
        })),
    ]),
    transition(':leave', [
        style({
            position: 'absolute',
        }),
        animate('320ms ease-in-out', style({
            opacity: 0,
        })),
    ]),
]);

export const MOB_NAV_ACTIVE_ICON_ANIMATION = trigger('navActiveIcon', [
    transition(':enter', [
        style({
            transform: 'scale(0.8)',
            opacity: 0,
            position: 'absolute',
        }),
        animate('500ms ease-in-out', keyframes([
            style({
                transform: 'scale(1.0)',
                opacity: 1,
                offset: 0.64,
            }),
            style({
                transform: 'rotate(4deg)',
                offset: 0.73,
            }),
            style({
                transform: 'rotate(-4deg)',
                offset: 0.82,
            }),
            style({
                transform: 'rotate(4deg)',
                offset: 0.91,
            }),
            style({
                transform: 'rotate(0deg)',
                offset: 1.0,
            }),
        ])),
    ]),
    transition(':leave', [
        style({
            position: 'absolute',
        }),
        animate('320ms ease-in-out', style({
            transform: 'scale(0.8)',
            opacity: 0,
        })),
    ]),
]);
