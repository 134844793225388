import {Injectable} from '@angular/core';
import {TimeTrackingService} from './time-tracking.service';
import {TimeTrackingStateService} from './time-tracking-state.service';

@Injectable({
    providedIn: 'root',
})
export class TimeTrackingServiceFactory {
    constructor(private readonly timeTrackingStateService: TimeTrackingStateService) {}

    public create(name: string): TimeTrackingService {
        return new TimeTrackingService(this.timeTrackingStateService, name);
    }
}
