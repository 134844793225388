import RecordRTC from 'recordrtc';

export const rtcConfig: any = {
    type: 'audio',
    mimeType: 'audio/wav',
    disableLogs: true,
    desiredSampRate: 22050,
    numberOfAudioChannels: 1,
    recorderType: RecordRTC.StereoAudioRecorder,
};
