<button
    class="menu__button menu__button_profile"
    [class.menu__button_active]="isActive"
    type="button"
    (click)="onClick()">
    <span class="menu__media">
        <nn-avatar
            class="menu__img"
            [avatarId]="user.AvatarId"
            [customerId]="user.Id"
            [customerName]="user.name"
            [customerLastName]="user.lastName"></nn-avatar>
    </span>
    <span class="menu__text">
        {{ "mobile_nav|account" | localization }}
    </span>
</button>
