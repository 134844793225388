<ng-container *ngIf="identityService.stateChanges | async as authState">
    <ng-container *ngIf="authState.user as user; else notLogged">
        <ng-container *ngIf="!user.isLocked">
            <button class="auth__add button-icon button-icon_add"
                *ngIf="user?.isWorker"
                [nnAnalyticsEvent]="'HeaderCreateResumeButtonClick'"
                matRipple
                routerLink="/resume-management">
                <div class="button-icon__media">
                    <mat-icon class="button-icon__icon button-icon__icon_plus mat-icon_plus"
                        svgIcon="sprite:icon-plus"></mat-icon>
                </div>
                <div class="button-icon__content">{{ 'home_page|resume' | localization }}</div>
            </button>
            <button class="auth__add button-icon button-icon_add"
                *ngIf="user?.isEmployer"
                [nnAnalyticsEvent]="'HeaderCreateVacancyButtonClick'"
                matRipple
                routerLink="/register/employer-success">
                <div class="button-icon__media">
                    <mat-icon class="button-icon__icon button-icon__icon_plus mat-icon_plus"
                        svgIcon="sprite:icon-plus"></mat-icon>
                </div>
                <div class="button-icon__content">{{ 'home_page|vacancy' | localization }}</div>
            </button>
            <nn-favorites-header
                class="auth__favorites">
            </nn-favorites-header>
            <nn-chat-header
                class="auth__chat">
            </nn-chat-header>
            <nn-notifications-header
                class="auth__notify"
            >
            </nn-notifications-header>
        </ng-container>
        <a class="auth__user-link"
            (click)="onUserClick(user)">
            <span class="auth__user-content">
                <span class="auth__user-text">{{ user?.name }}</span>
                <span class="auth__user-subtext">{{"home_page|personal_area" | localization}}</span>
            </span>
            <span class="auth__user-ava">
                <nn-avatar
                    [avatarId]="user.AvatarId"
                    [customerId]="user.Id"
                    [customerName]="user.name"
                    [customerLastName]="user.lastName"></nn-avatar>
            </span>
        </a>
    </ng-container>
    <ng-template #notLogged>
        <button class="auth__login"
            [routerLink]="['/login']"
            color="accent"
            mat-button>
            <span class="m-hide">{{"home_page|signin" | localization}}</span>
            <span class="m-show">
                <mat-icon class="mat-icon_login"
                    svgIcon="sprite:icon-login"></mat-icon>
            </span>
        </button>
    </ng-template>
</ng-container>
