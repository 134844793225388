import {from, Observable, ReplaySubject, Subject} from 'rxjs';
import {Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {getMessaging, getToken, Messaging} from 'firebase/messaging';
import {initializeApp} from 'firebase/app';
import {WINDOW_TOKEN} from "../dom/WINDOW_TOKEN";

@Injectable()
export class FcmClient {
    public fcmInitialized$: Observable<boolean>;
    private messaging: Messaging;
    private fcmInitializedSubject$: Subject<boolean>;

    constructor(zone: NgZone,
                @Inject(PLATFORM_ID) platformId: object,
                @Inject(WINDOW_TOKEN) window: Window,
    ) {
        if (isPlatformServer(platformId))
            return;

        this.fcmInitializedSubject$ = new ReplaySubject<boolean>(1);
        this.fcmInitialized$ = this.fcmInitializedSubject$.asObservable();

        if (!('Notification' in window) || Notification.permission === 'denied')
            return;

        zone.runOutsideAngular(() => {
            try {
                const app = initializeApp({
                    apiKey: 'AIzaSyBwrXJlF5lht7gDSzkfGHfhO03bDiLbREg',
                    authDomain: 'nashanyanya-dev.firebaseapp.com',
                    databaseURL: 'https://nashanyanya-dev.firebaseio.com',
                    projectId: 'nashanyanya-dev',
                    storageBucket: 'nashanyanya-dev.appspot.com',
                    messagingSenderId: '698414340082',
                    appId: '1:698414340082:web:26563b3e5e393567abbd6b'
                });
                this.messaging = getMessaging(app);
                this.fcmInitializedSubject$.next(true);
            } catch (e) {
                console.error('Unable to instantiate Firebase Messaging', e);
                this.fcmInitializedSubject$.next(false);
                return;
            }
        });
    }

    public getToken(): Observable<string> {
        return from(getToken(this.messaging, {vapidKey: 'BLivXr9oLEEnxtXqkQAzgAKgWMx4x4zRmyU7dhbsWiDarPSdB-4M5bdPI3JWeSycxu8oaL22_OI7FPH4OWy-wiA'}));
    }
}
