<div class="main">
    <nn-screen-size-detector *nnBrowserOnly></nn-screen-size-detector>

    <nn-common-header class="m-hide main__header"
        *nnScreenMode="'DESKTOP';Ssr:true"></nn-common-header>

    <main class="main__content">
        <router-outlet></router-outlet>
    </main>

    <nn-main-footer class="main__footer"
        *ngIf="showFooter$ | async"></nn-main-footer>

    <nn-mobile-nav
        class="m-show"
        *nnScreenMode="'MOBILE';Ssr:true"></nn-mobile-nav>

    <nn-layout-offline-message></nn-layout-offline-message>
    <nn-layout-error-message></nn-layout-error-message>
    <nn-layout-success-message></nn-layout-success-message>
</div>
