import {APP_INITIALIZER, makeEnvironmentProviders, PLATFORM_ID} from "@angular/core";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {isPlatformServer} from "@angular/common";
import {APP_CONFIG, AppConfig} from "../app-config/AppConfig";

function initializeMatIconRegistry(matIconRegistry: MatIconRegistry,
                                   domSanitizer: DomSanitizer,
                                   platformId: string,
                                   appConfig: AppConfig) {
    return () => {
        const svgUrl = '/assets/img/sprite.svg';
        const domain = (isPlatformServer(platformId)) ? appConfig.matIconsServersideBaseUrl : '';
        matIconRegistry.addSvgIconSetInNamespace('sprite', domSanitizer.bypassSecurityTrustResourceUrl(domain + svgUrl));
    };
}

export function provideMatIconRegistry() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initializeMatIconRegistry,
            deps: [MatIconRegistry, DomSanitizer, PLATFORM_ID, APP_CONFIG],
            multi: true,
        },
    ]);
}
