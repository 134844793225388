import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {MobileMenuItem} from "../../misc/mobileMenuItem";
import {MenuIcons} from "./items/menu-icons";
import {AsyncPipe, NgIf, NgTemplateOutlet} from "@angular/common";
import {ResumeSearchMenuItemComponent} from "./items/resume-search-menu-item/component";
import {NnActiveMobileMenuPipe} from "../../pipes/active-menu.pipe";
import {VacancySearchMenuItemComponent} from "./items/vacancy-search-menu-item/component";
import {RegularMenuItemComponent} from "./items/regular-menu-item/component";
import {LocalizationPipe} from "../../../../infrastructure/localization/localization.pipe";
import {TotalUnreadChatMessagesCountPipe} from "../../../../shared/pipes/total-unread-chat-messages-count.pipe";
import {ProfileMenuItemComponent} from "./items/profile-menu-item/component";
import {User} from "../../../../infrastructure/auth/models/user";
import {MobileMenuState} from "../../models/mobile-menu.state";

@Component({
    selector: "nn-menu-panel",
    templateUrl: "component.html",
    styleUrls: ["component.sass"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        NgTemplateOutlet,
        ResumeSearchMenuItemComponent,
        NnActiveMobileMenuPipe,
        VacancySearchMenuItemComponent,
        RegularMenuItemComponent,
        LocalizationPipe,
        TotalUnreadChatMessagesCountPipe,
        ProfileMenuItemComponent
    ]
})
export class MenuPanelComponent {
    protected readonly Menu = MobileMenuItem;
    protected readonly MenuIcons = MenuIcons;

    @Input({required: true}) user: User;
    @Input({required: true}) state: MobileMenuState;
    @Input({required: true}) items: MobileMenuItem[];
}
