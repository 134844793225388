<a class="notifications-header mat-elevation-z8"
    *ngIf="viewMode === 'full'"
    (click)="showNotificationsPanel()"
    [ngClass]="{'notifications-header_active': dialogOpened}">
        <div class="notifications-header__circle"
            mat-ripple>
            <mat-icon class="notifications-header__icon mat-icon_bell"
                svgIcon="sprite:icon-bell"></mat-icon>
        </div>
    <span
        class="notifications-header__count"
        *ngIf="unreadCount$ | async as count">{{ count }}</span>
</a>
<button class="notification"
    *ngIf="viewMode === 'short'"
    (click)="showNotificationsPanel()"
    type="button">
    <img class="notification__img"
        width="40px"
        height="44px"
        src="/assets/img/menu/bell.svg"
        alt="Bell">
    <span class="notification__count"
        *ngIf="unreadCount$ | async as count">
        {{ count }}
    </span>
</button>
