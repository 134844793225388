import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AlertsService} from '../../alerts/alerts.service';
import {inject} from "@angular/core";

const excludedCodes: number[] = [400, 404];

export const ApiErrorInterceptorFn: HttpInterceptorFn =
    (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
        const alertsService = inject(AlertsService);
        return next(req).pipe(
            catchError((err: unknown) => {
                if (err instanceof HttpErrorResponse && !excludedCodes.includes(err.status))
                    alertsService.notifyError(AlertsService.DEFAULT_ERROR_MSG);
                return throwError(() => err);
            }));
    }
