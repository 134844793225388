import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {AsyncPipe, NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {NnActiveMobileMenuPipe} from "../../pipes/active-menu.pipe";
import {LocalizationPipe} from "../../../../infrastructure/localization/localization.pipe";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MOB_NAV_SLIDE_UPDOWN_ANIMATION} from "../../animations/mob-nav-slide-updown.animation";
import {MenuSlidePanelComponent} from "../menu-slide-panel/component";
import {MenuPanelComponent} from "../menu-panel/component";
import {MobileNavService} from "../../services/mobile-nav.service";
import {MobileNavStateService} from "../../services/mobile-nav-state.service";

@Component({
    selector: "nn-mobile-nav",
    templateUrl: "component.html",
    styleUrls: ["component.sass"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [MOB_NAV_SLIDE_UPDOWN_ANIMATION],
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        NnActiveMobileMenuPipe,
        LocalizationPipe,
        RouterLinkActive,
        RouterLink,
        NgIf,
        NgTemplateOutlet,
        MenuSlidePanelComponent,
        MenuPanelComponent,
    ],
    providers: [MobileNavService, MobileNavStateService],
})
export class MobileNavComponent {
    private readonly navService = inject(MobileNavService);
    protected readonly state$ = inject(MobileNavStateService).state$;

    constructor() {
        this.navService.init();
    }
}

