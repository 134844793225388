<div class="category-vacancy">
    <a class="category-vacancy__item category-vacancy__item_nanny"
        [routerLink]="VacancyRoutes[WorkType.Nanny] | currentCityCatalogRouterLink | async"
        [routerLinkActive]="'category-vacancy__item_active'">
        <div class="category-vacancy__media">
            <img class="category-vacancy__image"
                src="/assets/img/menu/mobile_worknanny.jpg"
                [alt]="'home_page|header_find_nanny_work' | localization"/>
        </div>
        <div class="category-vacancy__text">{{ 'home_page|header_find_nanny_work' | localization }}</div>
        <div class="category-vacancy__count">{{ WorkType.Nanny | nnCatalogVacanciesCount | async | number }} {{ 'home_page|header_active_vacancies_count' | localization }}</div>
    </a>
    <a class="category-vacancy__item category-vacancy__item_housekeeper"
        [routerLink]="VacancyRoutes[WorkType.Housekeeper] | currentCityCatalogRouterLink | async"
        [routerLinkActive]="'category-vacancy__item_active'">
        <div class="category-vacancy__media">
            <img class="category-vacancy__image"
                src="/assets/img/menu/mobile_workhousekeeper.jpg"
                [alt]="'home_page|header_find_housekeeper_work' | localization"/>
        </div>
        <div class="category-vacancy__text">{{ 'home_page|header_find_housekeeper_work' | localization }}</div>
        <div class="category-vacancy__count">{{ WorkType.Housekeeper | nnCatalogVacanciesCount | async | number }} {{ 'home_page|header_active_vacancies_count' | localization }}</div>
    </a>
    <a class="category-vacancy__item category-vacancy__item_nurse"
        [routerLink]="VacancyRoutes[WorkType.Nurse] | currentCityCatalogRouterLink | async"
        [routerLinkActive]="'category-vacancy__item_active'">
        <div class="category-vacancy__media">
            <img class="category-vacancy__image"
                src="/assets/img/menu/mobile_worknurse.jpg"
                [alt]="'home_page|header_find_nurse_work' | localization"/>
        </div>
        <div class="category-vacancy__text">{{ 'home_page|header_find_nurse_work' | localization }}</div>
        <div class="category-vacancy__count">{{ WorkType.Nurse | nnCatalogVacanciesCount | async | number }} {{ 'home_page|header_active_vacancies_count' | localization }}</div>
    </a>
    <a class="category-vacancy__item category-vacancy__item_tutor"
        [routerLink]="VacancyRoutes[WorkType.Tutor] | currentCityCatalogRouterLink | async"
        [routerLinkActive]="'category-vacancy__item_active'">
        <div class="category-vacancy__media">
            <img class="category-vacancy__image"
                src="/assets/img/menu/mobile_worktutor.jpg"
                [alt]="'home_page|header_find_tutor_work' | localization"/>
        </div>
        <div class="category-vacancy__text">{{ 'home_page|header_find_tutor_work' | localization }}</div>
        <div class="category-vacancy__count">{{ WorkType.Tutor | nnCatalogVacanciesCount | async | number }} {{ 'home_page|header_active_vacancies_count' | localization }}</div>
    </a>
    <a class="category-vacancy__item category-vacancy__item_petcare"
        [routerLink]="VacancyRoutes[WorkType.PetCare] | currentCityCatalogRouterLink | async"
        [routerLinkActive]="'list__link_active'">
        <div class="category-vacancy__media">
            <img class="category-vacancy__image"
                src="/assets/img/menu/mobile_workpetcare.jpg"
                [alt]="'home_page|header_find_petcare_work' | localization"/>
        </div>
        <div class="category-vacancy__text">{{ 'home_page|header_find_petcare_work' | localization }}</div>
        <div class="category-vacancy__count">{{ WorkType.PetCare | nnCatalogVacanciesCount | async | number }} {{ 'home_page|header_active_vacancies_count' | localization }}</div>
    </a>
    <a class="category-vacancy__item category-vacancy__item_cook"
        [routerLink]="VacancyRoutes[WorkType.Cook] | currentCityCatalogRouterLink | async"
        [routerLinkActive]="'list__link_active'">
        <div class="category-vacancy__media">
            <img class="category-vacancy__image"
                src="/assets/img/menu/mobile_workcook.jpg"
                [alt]="'home_page|header_find_cook_work' | localization"/>
        </div>
        <div class="category-vacancy__text">{{ 'home_page|header_find_cook_work' | localization }}</div>
        <div class="category-vacancy__count">{{ WorkType.Cook | nnCatalogVacanciesCount | async | number }} {{ 'home_page|header_active_vacancies_count' | localization }}</div>
    </a>
    <a class="category-vacancy__item category-vacancy__item_driver"
        [routerLink]="VacancyRoutes[WorkType.Driver] | currentCityCatalogRouterLink | async"
        [routerLinkActive]="'list__link_active'">
        <div class="category-vacancy__media">
            <img class="category-vacancy__image"
                src="/assets/img/menu/mobile_workdriver.jpg"
                [alt]="'home_page|header_find_driver_work' | localization"/>
        </div>
        <div class="category-vacancy__text">{{ 'home_page|header_find_driver_work' | localization }}</div>
        <div class="category-vacancy__count">{{ WorkType.Driver | nnCatalogVacanciesCount | async | number }} {{ 'home_page|header_active_vacancies_count' | localization }}</div>
    </a>
</div>
