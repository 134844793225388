import {Injectable} from '@angular/core';
import {Notification} from '../models/notification';

@Injectable()
export class TrackingNewNotificationsService {
    private readonly set: Set<string> = new Set<string>();

    public trackRange(notifications: Notification[]): void {
        if (!notifications || notifications.length === 0) return;
        notifications
            .filter((notification) => !notification.isViewed && !this.set.has(notification.notificationId))
            .forEach(notification => this.set.add(notification.notificationId));
    }

    public tracked(notification: Notification): boolean {
        return notification ? this.set.has(notification.notificationId) : false;
    }

    public untrack(notification: Notification): boolean {
        return notification ? this.set.delete(notification.notificationId) : false;
    }

    public untrackAll(): void {
        this.set.clear();
    }
}
