import {makeEnvironmentProviders} from "@angular/core";
import {FcmService} from "./fcm.service";
import {FcmClient} from "./FcmClient";
import {nullFactory} from "../helpers/null.factory";

export function provideNoopFcmServices() {
    return makeEnvironmentProviders([{
        provide: FcmService,
        useFactory: nullFactory
    }, {
        provide: FcmClient,
        useFactory: nullFactory
    }]);
}

export function provideFcmServices() {
    return makeEnvironmentProviders([FcmClient, FcmService]);
}
