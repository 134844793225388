import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {User} from '../../../infrastructure/auth/models/user';
import {filter, switchMap} from 'rxjs/operators';
import {ImageDialogService} from '../../../infrastructure/image-processing/image-dialog.service';
import {UserProfileFacade} from '../../../core/users/core/user-profile.facade';
import {AvatarComponent} from "../../../pages/common/avatar/component";
import {LocalizationPipe} from "../../../infrastructure/localization/localization.pipe";
import {RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
    selector: 'nn-user-info',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AvatarComponent,
        LocalizationPipe,
        RouterLink,
        NgIf
    ]
})
export class UserInfoComponent implements OnInit {
    @Input() public user: User;
    public suggestPremium: boolean;

    constructor(
        private readonly imageService: ImageDialogService,
        private readonly userProfileFacade: UserProfileFacade,
    ) {
    }

    public ngOnInit(): void {
        this.suggestPremium = !this.user.isPremium;
    }

    public onUploadAvatarClick(): void {
        this.imageService.getImage().pipe(
            filter((avatarId) => !!avatarId),
            switchMap((avatarId) => this.userProfileFacade.updateAvatar(avatarId)),
        ).subscribe();
    }
}
