import {APP_INITIALIZER, makeEnvironmentProviders} from '@angular/core';
import {LocalizationService} from './localization.service';

function initFactory(
    localizationService: LocalizationService) {
    return () => localizationService.init();
}

export function provideLocalization() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initFactory,
            deps: [LocalizationService],
            multi: true,
        }
    ]);
}
