<div class="category">
    <div class="category__list">
        <a class="category__item category__item_nanny"
            [routerLink]="ResumeRoutes[WorkType.Nanny] | currentCityCatalogRouterLink | async"
            routerLinkActive="category__item_active">
            <div class="category__media">
                <img class="category__image"
                    src="/assets/img/menu/nanny.jpg"
                    [alt]="'home_page|header_find_nanny_worker' | localization"/>
            </div>
            <div class="category__text">{{ 'home_page|header_find_nanny_worker' | localization }}</div>
        </a>
        <a class="category__item category__item_housekeeper"
            [routerLink]="ResumeRoutes[WorkType.Housekeeper] | currentCityCatalogRouterLink | async"
            routerLinkActive="category__item_active">
            <div class="category__media">
                <img class="category__image"
                    src="/assets/img/menu/housekeeper.jpg"
                    [alt]="'home_page|header_find_housekeeper_worker' | localization"/>
            </div>
            <div class="category__text">{{ 'home_page|header_find_housekeeper_worker' | localization }}</div>
        </a>
        <a class="category__item category__item_nurse"
            [routerLink]="ResumeRoutes[WorkType.Nurse] | currentCityCatalogRouterLink | async"
            routerLinkActive="category__item_active">
            <div class="category__media">
                <img class="category__image"
                    src="/assets/img/menu/nurse.jpg"
                    [alt]="'home_page|header_find_nurse_worker' | localization"/>
            </div>
            <div class="category__text">{{ 'home_page|header_find_nurse_worker' | localization }}</div>
        </a>
        <a class="category__item category__item_tutor"
            [routerLink]="ResumeRoutes[WorkType.Tutor] | currentCityCatalogRouterLink | async"
            routerLinkActive="category__item_active">
            <div class="category__media">
                <img class="category__image"
                    src="/assets/img/menu/tutor.jpg"
                    [alt]="'home_page|header_find_tutor_worker' | localization"/>
            </div>
            <div class="category__text">{{ 'home_page|header_find_tutor_worker' | localization }}</div>
        </a>
        <a class="category__item category__item_petcare"
            [routerLink]="ResumeRoutes[WorkType.PetCare] | currentCityCatalogRouterLink | async"
            routerLinkActive="category__item_active">
            <div class="category__media">
                <img class="category__image"
                    src="/assets/img/menu/petcare.jpg"
                    [alt]="'home_page|header_find_petcare_worker' | localization"/>
            </div>
            <div class="category__text">{{ 'home_page|header_find_petcare_worker' | localization }}</div>
        </a>
        <a class="category__item category__item_cook"
            [routerLink]="ResumeRoutes[WorkType.Cook] | currentCityCatalogRouterLink | async"
            routerLinkActive="category__item_active">
            <div class="category__media">
                <img class="category__image"
                    src="/assets/img/menu/cook.jpg"
                    [alt]="'home_page|header_find_cook_worker' | localization"/>
            </div>
            <div class="category__text">{{ 'home_page|header_find_cook_worker' | localization }}</div>
        </a>
        <a class="category__item category__item_driver"
            [routerLink]="ResumeRoutes[WorkType.Driver] | currentCityCatalogRouterLink | async"
            routerLinkActive="category__item_active">
            <div class="category__media">
                <img class="category__image"
                    src="/assets/img/menu/driver.jpg"
                    [alt]="'home_page|header_find_driver_worker' | localization"/>
            </div>
            <div class="category__text">{{ 'home_page|header_find_driver_worker' | localization }}</div>
        </a>
    </div>
</div>
