import {ChangeDetectionStrategy, Component, inject, Input} from "@angular/core";
import {LocalizationPipe} from "../../../../../../infrastructure/localization/localization.pipe";
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MobileMenuItem} from "../../../../misc/mobileMenuItem";
import {NnActiveMobileMenuPipe} from "../../../../pipes/active-menu.pipe";
import {MenuIcons} from "../menu-icons";
import {MOB_NAV_ACTIVE_ICON_ANIMATION, MOB_NAV_ICON_ANIMATION} from "../../../../animations/mob-nav-icon.animation";
import {
    MOB_NAV_ACTIVE_VACANCIES_ICON_ANIMATION,
    MOB_NAV_VACANCIES_ICON_ANIMATION
} from "../../../../animations/mob-nav-vacancies-icon.animation";
import {MobileNavToggleService} from "../../../../services/mobile-nav-toggle.service";

@Component({
    selector: "nn-vacancy-search-menu-item",
    templateUrl: "component.html",
    styleUrls: ["component.sass"],
    animations: [
        MOB_NAV_ICON_ANIMATION,
        MOB_NAV_ACTIVE_ICON_ANIMATION,
        MOB_NAV_VACANCIES_ICON_ANIMATION,
        MOB_NAV_ACTIVE_VACANCIES_ICON_ANIMATION,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LocalizationPipe,
        NgIf,
        RouterLink,
        RouterLinkActive,
        NnActiveMobileMenuPipe,
        AsyncPipe,
        NgClass,
    ],
})
export class VacancySearchMenuItemComponent {
    @Input({required: true}) loggedIn: boolean;
    @Input({required: true}) isActive: boolean;

    private readonly navigationService = inject(MobileNavToggleService);

    protected onClick(): void {
        this.navigationService.toggleSlidePanel(MobileMenuItem.VacancySearch);
    }

    protected readonly MenuIcons = MenuIcons;
}
