import {Inject, Injectable} from '@angular/core';
import {WINDOW_TOKEN} from '../../../../../infrastructure/dom/WINDOW_TOKEN';

@Injectable({
    providedIn: 'root',
})
export class TimeTrackingStateService {
    private readonly localStorage: Storage;

    constructor(@Inject(WINDOW_TOKEN) window: Window) {
        this.localStorage = window?.localStorage;
    }

    public reset(userId: string, prefix: string): void {
        this.localStorage?.removeItem(`${userId}-${prefix}-timetracking`);
    }

    public getElapsed(userId: string, prefix: string): number | null {
        const elapsed = this.localStorage?.getItem(`${userId}-${prefix}-timetracking`);
        return elapsed == null ? null : Number(elapsed);
    }

    public increase(userId: string, prefix: string, ms: number): number {
        const elapsed = (this.getElapsed(userId, prefix) ?? 0) + (ms || 0);
        this.localStorage?.setItem(`${userId}-${prefix}-timetracking`, elapsed.toString());
        return elapsed;
    }
}
