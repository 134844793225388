'use strict';

var test = {
  __proto__: null,
  foo: {}
};
var $Object = Object;

/** @type {import('.')} */
module.exports = function hasProto() {
  // @ts-expect-error: TS errors on an inherited property for some reason
  return {
    __proto__: test
  }.foo === test.foo && !(test instanceof $Object);
};