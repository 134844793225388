<div class="user"
    *ngIf="user">
    <div class="user__media">
        <div class="user__img">
            <nn-avatar
                [avatarId]="user.AvatarId"
                [customerId]="user.Id"
                [customerName]="user.name"
                [customerLastName]="user.lastName"
                (click)="onUploadAvatarClick()"></nn-avatar>
        </div>
    </div>
    <div class="user__content">
        <div class="user__title">
            <div class="user__title-inner">{{ user.name + ' ' + user.lastName }}</div>
            <div
                class="user__badge m-hide"
                *ngIf="user.isPremium">
                <div class="premium-badge">
                    <div class="premium-badge__text">{{ 'dashboard_page|premium_badge' | localization }}</div>
                </div>
            </div>
        </div>
        <div
            class="premium-badge m-show"
            *ngIf="user.isPremium">
            <div class="premium-badge__text">{{ 'dashboard_page|premium_badge' | localization }}</div>
        </div>
        <ng-container *ngIf="user.isPremium">
            <div class="user__label">{{ (user.isWorker
                ? 'dashboard_page|worker_personal_cabinet'
                : 'dashboard_page|employer_personal_cabinet') | localization }}
            </div>
        </ng-container>
        <ng-container *ngIf="suggestPremium">
            <a class="user__link"
                routerLink="/buy">{{ 'dashboard_page|go_to_premium' | localization }}</a>
        </ng-container>
    </div>
</div>
