import {makeEnvironmentProviders} from "@angular/core";
import {MAT_BOTTOM_SHEET_DEFAULT_OPTIONS} from "@angular/material/bottom-sheet";
import {LanguageService} from "../localization/language.service";
import {NANNY_MAT_BOTTOM_SHEET_DEFAULT_CONFIG} from "./nanny-mat-bs-default-config";
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomDateAdapter} from "./CustomDateAdapter";
import {Platform} from "@angular/cdk/platform";
import {MATERIAL_DATEPICKER_FORMATS} from "./MATERIAL_DATEPICKER_FORMATS";
import {AppDefaultErrorStateMatcher} from "./AppDefaultErrorStateMatcher";

export function provideMaterialConfigs(){
    return makeEnvironmentProviders([
        {provide: ErrorStateMatcher, useClass: AppDefaultErrorStateMatcher},
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]},
        {provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS},
        {
            provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
            useFactory: (languageService: LanguageService) => ({
                ...NANNY_MAT_BOTTOM_SHEET_DEFAULT_CONFIG,
                direction: languageService.currentTextDirection,
            }),
            deps: [LanguageService],
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useFactory: (languageService: LanguageService) => ({
                direction: languageService.currentTextDirection,
            }),
            deps: [LanguageService],
        },
    ]);
}
