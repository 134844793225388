import {inject, Pipe, PipeTransform} from '@angular/core';
import {COUNTRY_DOMAIN} from '../../infrastructure/app-config/AppConfig';
import {AppStoreLinkProvider} from '../../infrastructure/app-store-link.provider';

@Pipe({
    name: 'nnAppStoreLink',
    pure: true,
    standalone: true,
})
export class AppStoreLinkPipe implements PipeTransform {
    private readonly linkProvider = inject(AppStoreLinkProvider);

    public transform(countryDomain: keyof typeof COUNTRY_DOMAIN): string {
        return this.linkProvider.getLink(COUNTRY_DOMAIN[countryDomain]);
    }
}
