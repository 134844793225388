import {inject, Injectable} from "@angular/core";
import {AppEventsService} from "../../../infrastructure/events/app-events.service";
import {filter} from "rxjs/operators";
import {isAuthLoggedInEvent, isAuthLoginForbiddenEvent} from "./auth-app-events";
import {AuthRedirectService} from "./auth-redirect.service";

@Injectable({providedIn: "root"})
export class AuthRedirectSubscriptions {
    private readonly redirectsService = inject(AuthRedirectService);
    private readonly appEvents = inject(AppEventsService);

    public subscribe(): void {
        this.appEvents.events$.pipe(
            filter(event => isAuthLoggedInEvent(event) && event.requiresRedirect),
        ).subscribe(() => this.redirectsService.onLogin());

        this.appEvents.events$.pipe(
            filter(event => isAuthLoginForbiddenEvent(event)),
        ).subscribe(() => this.redirectsService.forbidden());
    }
}
