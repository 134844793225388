import {Inject, Injectable, NgZone, OnDestroy} from '@angular/core';
import {fromEvent, merge, Observable, ReplaySubject, Subject, timer} from 'rxjs';
import {delay, filter, map, retryWhen, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ConnectionStatusServiceOptions} from './connection-status-service.options';
import {ConnectionState} from './ConnectionState';
import {HttpBackend, HttpEventType, HttpRequest} from '@angular/common/http';
import {SentryWrapper} from '../sentry/sentry-wrapper.service';
import {Severity} from '@sentry/types';
import {ServiceWorkerBypassService} from '../api/sw-bypass/service-worker-bypass.service';
import {APP_CONFIG, AppConfig} from '../app-config/AppConfig';
import {WINDOW_TOKEN} from "../dom/WINDOW_TOKEN";

@Injectable({providedIn: 'root'})
export class ConnectionStatusService implements OnDestroy {
    private statusSubject$: Subject<ConnectionState> = new ReplaySubject<ConnectionState>(1);
    private destroySubject$: Subject<void> = new Subject<void>();
    private options: ConnectionStatusServiceOptions;

    private currentState: ConnectionState = {
        hasInternetAccess: true,
        hasNetworkConnection: true,
    };

    constructor(private httpBackend: HttpBackend,
                private sentryWrapper: SentryWrapper,
                private swBypass: ServiceWorkerBypassService,
                private ngZone: NgZone,
                @Inject(APP_CONFIG) appConfig: AppConfig,
                @Inject(WINDOW_TOKEN) private readonly window: Window,
    ) {
        this.options = appConfig.connectionStatusOptions;
    }

    public get status$(): Observable<ConnectionState> {
        return this.statusSubject$.asObservable();
    }

    public init() {
        this.ngZone.runOutsideAngular(() => {
            this.initNetworkStateCheck();
            this.initInternetStateCheck();
        });
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    private initNetworkStateCheck() {
        if (!this.window) return;
        merge(
            fromEvent(this.window, 'online').pipe(map(() => true)),
            fromEvent(this.window, 'offline').pipe(map(() => false)),
        ).pipe(
            tap(networkStatus => {
                this.currentState.hasNetworkConnection = networkStatus;
                this.emitEvent();
            }),
            takeUntil(this.destroySubject$),
        ).subscribe();
    }

    private initInternetStateCheck() {
        timer(0, this.options.heartbeatInterval).pipe(
            switchMap(() => {
                let request = new HttpRequest(this.options.requestMethod, this.options.heartbeatUrl, {responseType: 'text'});
                request = this.swBypass.setHeader(request);
                return this.httpBackend.handle(request);
            }),
            filter(event => event.type === HttpEventType.Response),
            retryWhen(errors => errors.pipe(
                tap(() => {
                    this.sentryWrapper.addBreadCrumb({
                        message: 'heartbeat request failed',
                        level: Severity.Warning,
                        category: 'ConnectionStatusService',
                    });
                    this.currentState.hasInternetAccess = false;
                    this.emitEvent();
                }),
                delay(this.options.heartbeatRetryInterval),
            )),
            takeUntil(this.destroySubject$),
        ).subscribe(() => {
            this.currentState.hasInternetAccess = true;
            this.emitEvent();
        });
    }

    private emitEvent() {
        this.ngZone.run(() => this.statusSubject$.next(this.currentState));
    }

}
