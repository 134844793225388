<button
    class="menu__button"
    type="button"
    [routerLink]="link"
    routerLinkActive="menu__button_active"
    [routerLinkActiveOptions]="{exact: true}">
    <span class="menu__media">
        <img
            class="menu__img"
            *ngIf="!isActive"
            @navIcon
            [src]="icon"
            [alt]="title"/>
        <img
            class="menu__img"
            *ngIf="isActive"
            @navActiveIcon
            [src]="highlightedIcon"
            [alt]="title"/>
        <ng-content></ng-content>
    </span>
    <span class="menu__text">
        {{ title }}
    </span>
</button>
