'use strict';

var GetIntrinsic = require('get-intrinsic');
var $String = GetIntrinsic('%String%');
var $TypeError = require('es-errors/type');

// https://262.ecma-international.org/6.0/#sec-tostring

module.exports = function ToString(argument) {
  if (typeof argument === 'symbol') {
    throw new $TypeError('Cannot convert a Symbol value to a string');
  }
  return $String(argument);
};