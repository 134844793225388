<div class="interesting">
    <button class="interesting__button interesting__button_green"
        type="button"
        routerLink="/account"
        fragment="files">
        <div class="interesting__media">
            <img class="interesting__img"
                src="/assets/img/interesting/docs.svg"
                [alt]="'home_page|interesting_docs_title' | localization">
        </div>
        <div class="interesting__content">
            <div class="interesting__title">{{ 'home_page|interesting_docs_title' | localization }}</div>
            <div class="interesting__text"
                *nnScreenMode="'DESKTOP'">{{ 'home_page|interesting_docs_description' | localization }}</div>
        </div>
    </button>
    <button class="interesting__button interesting__button_blue"
        type="button"
        routerLink="/account"
        fragment="audio">
        <div class="interesting__media">
            <img class="interesting__img"
                src="/assets/img/interesting/audio.svg"
                [alt]="'home_page|interesting_audio_title' | localization">
        </div>
        <div class="interesting__content">
            <div class="interesting__title">{{ 'home_page|interesting_audio_title' | localization }}</div>
            <div class="interesting__text"
                *nnScreenMode="'DESKTOP'">{{ 'home_page|interesting_audio_description' | localization }}</div>
        </div>
    </button>
    <button class="interesting__button interesting__button_yellow"
        type="button"
        routerLink="/account"
        fragment="employment-history">
        <div class="interesting__media">
            <img class="interesting__img"
                src="/assets/img/interesting/history.svg"
                [alt]="'home_page|interesting_jobhistory_title' | localization">
        </div>
        <div class="interesting__content">
            <div class="interesting__title">{{ 'home_page|interesting_jobhistory_title' | localization }}</div>
            <div class="interesting__text"
                *nnScreenMode="'DESKTOP'">{{ 'home_page|interesting_jobhistory_description' | localization }}</div>
        </div>
    </button>
    <button class="interesting__button interesting__button_purple"
        type="button"
        routerLink="/worker-data">
        <div class="interesting__media">
            <img class="interesting__img"
                src="/assets/img/interesting/about.svg"
                [alt]="'home_page|interesting_about_title' | localization">
        </div>
        <div class="interesting__content">
            <div class="interesting__title">{{ 'home_page|interesting_about_title' | localization }}</div>
            <div class="interesting__text"
                *nnScreenMode="'DESKTOP'">{{ 'home_page|interesting_about_description' | localization }}</div>
        </div>
    </button>
</div>
