import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ServiceWorkerBypassService} from './service-worker-bypass.service';

export function provideServiceWorkerBypass() {
    return {
        provide: HTTP_INTERCEPTORS,
        useClass: ServiceWorkerBypassService,
        multi: true,
    };
}
