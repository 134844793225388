import {inject, Injectable, PLATFORM_ID} from "@angular/core";
import {IdentityService} from "../../users/core/identity.service";
import {MonolithApiClient} from "./api";
import {CatalogStatsService} from "./catalog-stats.service";
import {AppEventsService} from "../../../infrastructure/events/app-events.service";
import {isPlatformServer} from "@angular/common";
import {filter, map, switchMap, withLatestFrom} from "rxjs/operators";
import {isCatalogItemViewedEvent} from "./catalog-app-events";
import {CatalogType} from "../../../pages/catalog/routing/CatalogType";
import {GuidUtils} from "../../../infrastructure/helpers/guid.utils";
import {CatalogEventType} from "./api/catalog-search-api-client";
import {CatalogLimitingService} from "./catalog-limiting.service";

@Injectable({
    providedIn: 'root',
})
export class CatalogViewEventHandlers {
    private readonly platformId = inject(PLATFORM_ID);
    private readonly identityService = inject(IdentityService);
    private readonly catalogClient = inject(MonolithApiClient,);
    private readonly catalogStatsService = inject(CatalogStatsService);
    private readonly catalogLimitingService = inject(CatalogLimitingService);
    private readonly appEvents = inject(AppEventsService);

    public subscribe() {
        if (isPlatformServer(this.platformId)) return;
        const user$ = this.identityService.stateChanges.pipe(map(authState => authState.user));

        this.appEvents.events$.pipe(
            filter(event => isCatalogItemViewedEvent(event)),
            filter(event => event.isItemPublished),
        ).subscribe(({catalogType, itemId, itemWorkType, utmSource}) =>
            this.catalogLimitingService.addView(catalogType, itemId, itemWorkType, utmSource)
        )

        const resumeViews$ = this.appEvents.events$.pipe(
            filter(event => isCatalogItemViewedEvent(event)),
            filter(event => event.catalogType === CatalogType.Resume),
            withLatestFrom(user$),
        );

        const vacancyViews$ = this.appEvents.events$.pipe(
            filter(event => isCatalogItemViewedEvent(event)),
            filter(event => event.catalogType === CatalogType.Vacancy),
            withLatestFrom(user$),
        );

        resumeViews$.pipe(
            filter(([, user]) => user && user.isEmployer && !user.isSupport),
            switchMap(([{itemId}, user]) => this.catalogClient.employerOwnViewLogs_AddResumeViewLog(user.Id, itemId))
        ).subscribe();

        resumeViews$.pipe(
            filter(([{itemOwnerId}, user]) => !user || !user.isSupport && user.Id !== GuidUtils.normalize(itemOwnerId)),
            switchMap(([{itemId}, user]) => this.catalogStatsService.addEvent(CatalogType.Resume, CatalogEventType.View, itemId, user?.Id))
        ).subscribe();

        vacancyViews$.pipe(
            filter(([, user]) => user && user.isWorker && !user.isSupport),
            switchMap(([{itemId}, user]) => this.catalogClient.workerOwnViewLogs_AddVacancyViewLog(user.Id, itemId))
        ).subscribe();
        vacancyViews$.pipe(
            filter(([{itemOwnerId}, user]) => !user || !user.isSupport && user.Id !== GuidUtils.normalize(itemOwnerId)),
            switchMap(([{itemId}, user]) => this.catalogStatsService.addEvent(CatalogType.Vacancy, CatalogEventType.View, itemId, user?.Id))
        ).subscribe();
    }
}
