import {DEFAULT_CURRENCY_CODE, makeEnvironmentProviders} from "@angular/core";
import {APP_CONFIG, AppConfig} from "../app-config/AppConfig";

export function provideDefaultCurrencyCode() {
    return makeEnvironmentProviders([
        {
            provide: DEFAULT_CURRENCY_CODE,
            useFactory: (appConfig: AppConfig) => appConfig.currency,
            deps: [APP_CONFIG],
        },
    ]);
}
