<div class="dialog dialog_blue"
    *ngIf="notificationsState$ | async as notificationsState">
    <nn-dialog-title class="dialog__header dialog__header_big"
        [title]="'news_dialog|title' | localization"></nn-dialog-title>
    <div class="dialog__content"
        mat-dialog-content>
        <ng-container *ngIf="!notificationsState.isLoading; else notificationsLoading">
            <ng-container *ngIf="!notificationsState.isEmpty; else notificationsEmpty">
                <div class="dialog__scroll">
                    <div class="dialog__inner">
                        <ul class="news-list">
                            <li class="news-list__item"
                                *ngFor="let notification of notificationsState.notifications">
                                <div class="news-item">
                                    <button class="news-item__content"
                                        (click)="onNotificaitonClick(notification)">
                                        <div class="news-item__title">
                                            <span>{{ notification.title }}</span>
                                            <span class="badge badge_green"
                                                *ngIf="notification.isNew">{{ 'news_dialog|new' | localization }}</span>
                                        </div>
                                        <div class="news-item__text">{{ notification.body }}</div>
                                    </button>
                                    <div class="news-item__media">
                                        <button class="news-item__circle"
                                            (click)="onNotificaitonClick(notification)">
                                            <img class="news-item__img"
                                                *ngIf="notification.icon"
                                                [src]="notification.icon"
                                                alt="notification-image"/>
                                            <img class="news-item__img"
                                                *ngIf="!notification.icon"
                                                src="/assets/img/notifications/default-icon.png"
                                                alt="notification-image"/>
                                        </button>
                                        <button class="news-item__button"
                                            (click)="onArchive(notification, $event)">
                                            <mat-icon class="mat-icon_checkmark"
                                                svgIcon="sprite:icon-checkmark"></mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="dialog__footer">
        <div class="news-list__clear">
            <button class="button-icon button-icon_small"
                [disabled]="notificationsState.isLoading || notificationsState.isEmpty"
                (click)="archiveAll()"
                matRipple>
                <div class="button-icon__media">
                    <mat-icon class="mat-icon_checkmark"
                        svgIcon="sprite:icon-checkmark"></mat-icon>
                </div>
                <div class="button-icon__content">{{ 'news_dialog|clear' | localization }}</div>
            </button>
        </div>
        <div class="news-list__settings">
            <button class="button-icon button-icon_small"
                [routerLink]="['/account/preferences']"
                matRipple>
                <div class="button-icon__media">
                    <mat-icon class="mat-icon_settings"
                        svgIcon="sprite:icon-settings"></mat-icon>
                </div>
                <div class="button-icon__content">{{ 'news_dialog|settings' | localization }}</div>
            </button>
        </div>
    </div>
</div>
<ng-template #notificationsLoading>
    <mat-progress-spinner class="notifications-loading"
        diameter="50"
        mode="indeterminate"
        strokeWidth="5"></mat-progress-spinner>
</ng-template>
<ng-template #notificationsEmpty>
    <div class="dialog__scroll">
        <div class="dialog__inner">
            <div class="news-list__empty">{{ 'news_dialog|empty' | localization }}</div>
        </div>
    </div>
</ng-template>
