import {inject, Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NavigationPanelService {
    private readonly dialog = inject(MatDialog);

    public open(): void {
        this.dialog.closeAll();

        this.dialog.afterAllClosed
            .pipe(take(1))
            .subscribe(() => void this.openDialog());
    }

    private async openDialog(): Promise<MatDialogRef<unknown>> {
        const {NavigationPanelDialogComponent} = await import('../dialog/component');
        const config = NavigationPanelDialogComponent.dialogConfig;
        return this.dialog.open(NavigationPanelDialogComponent, config);
    }
}
