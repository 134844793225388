import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {filter, mergeMap, switchMap} from 'rxjs/operators';
import {ChatService} from '../../chat/core/messaging/chat.service';
import {LoginService} from "./session/login.service";

@Injectable({
    providedIn: 'root',
})
export class UserLockStatusService {
    constructor(
        private readonly router: Router,
        private readonly loginService: LoginService,
        private readonly chatService: ChatService) {
    }

    public init(): void {
        this.chatService.lockStatus$.pipe(
            filter(isBlocked => isBlocked),
            mergeMap(() => this.loginService.logout()),
            switchMap(() => this.router.navigate(['/login'])),
        ).subscribe();
    }
}
