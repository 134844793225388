import {inject, Injectable} from '@angular/core';
import {MonolithApiClient, ServiceFeatures} from './api';
import {Observable} from 'rxjs';
import {IdentityService} from '../../users/core/identity.service';

@Injectable({
    providedIn: 'root',
})
export class CompletedFeaturesService {
    private readonly api = inject(MonolithApiClient);
    private readonly identity = inject(IdentityService);

    public get(): Observable<ServiceFeatures[]> {
        return this.api.customersCompletedFeatures_Get(this.identity.currentState.user?.Id);
    }

    public complete(feature: ServiceFeatures): Observable<void> {
        return this.api.customersCompletedFeatures_Complete(this.identity.currentState.user?.Id, feature);
    }
}
