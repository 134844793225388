import {Injectable} from '@angular/core';
import {NotificationsApiClient, TokenType, UserDeviceDto} from '../api/notifications-api-client';
import {map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IdentityService} from '../../users/core/identity.service';

@Injectable({providedIn: 'root'})
export class UserDeviceTokensService {
    constructor(private readonly notificationsApiClient: NotificationsApiClient,
                private readonly identityService: IdentityService,
    ) {}

    public addUserDeviceToken(token: string) {
        const deviceDto = new UserDeviceDto({
            tokenType: TokenType.FCM,
            token: token,
            sandbox: false,
        });
        return this.getUserId().pipe(
            switchMap(userId => this.notificationsApiClient.users_AddUserDevice(userId, deviceDto)),
        );
    }

    private getUserId(): Observable<string> {
        return this.identityService.stateChanges.pipe(
            take(1),
            map(authState => authState.user),
            map(user => {
                if (user) return user.Id;
                throw new Error('User is not authenticated');
            }),
        );
    }
}
