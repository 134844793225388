import {Injectable} from '@angular/core';
import {DeviceInfoDto, MonolithApiClient} from './api';
import {IdentityService} from '../../users/core/identity.service';
import {DeviceDetectorService, DeviceInfo} from 'ngx-device-detector';
import {mergeMap, Observable, of} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {User} from '../../../infrastructure/auth/models/user';
import {AppEventsService} from '../../../infrastructure/events/app-events.service';
import {AuthAppEvents} from '../../users/core/auth-app-events';

@Injectable({
    providedIn: 'root',
})
export class ClientLoggedInHistoryService {

    constructor(
        private readonly identityService: IdentityService,
        private readonly monolithApiClient: MonolithApiClient,
        private readonly deviceService: DeviceDetectorService,
        private readonly appEvents: AppEventsService) {
    }

    public startWaitingForLoggedInEvents(): void {
        this.appEvents.events$.pipe(
            filter(event => event.eventName === AuthAppEvents.LoggedIn),
            switchMap(() => this.getUser()),
            mergeMap(user => this.internalAddLog(user))).subscribe();
    }

    private mapDeviceInfoToDto(deviceInfo: DeviceInfo): DeviceInfoDto {
        return new DeviceInfoDto({
            browser: deviceInfo.browser,
            browserVersion: deviceInfo.browser_version,
            device: deviceInfo.device,
            os: deviceInfo.os,
            osVersion: deviceInfo.os_version,
            userAgent: deviceInfo.userAgent,
        });
    }

    private getUser(): Observable<User> {
        return this.identityService.stateChanges.pipe(
            take(1),
            map(authState => authState.user));
    }

    private internalAddLog(user: User): Observable<void> {
        if (!user) return of(null);
        const deviceInfo = this.deviceService.getDeviceInfo();
        const deviceInfoDto = this.mapDeviceInfoToDto(deviceInfo);
        return this.monolithApiClient.customerClientHistory_AddLog(user.Id, deviceInfoDto);
    }
}
