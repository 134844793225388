export function reloadPage() {
    const storageKey = 'OnLoadChunkFailedPageReload';
    const debounceTime: number = 5 * 60 * 1000; // 5 minutes

    const storageEntry = window.sessionStorage.getItem(storageKey);
    const lastReloadTime: Date = !!storageEntry ? new Date(storageEntry) : null;
    const now = new Date();
    const lastReloadTimeMs: number = lastReloadTime && lastReloadTime.getTime() || 0;

    const diffMs = now.getTime() - lastReloadTimeMs;
    if (diffMs > debounceTime) {
        window.sessionStorage.setItem(storageKey, now.toString());
        location.reload();
    }
}
