<div class="notify notify_header"
    *ngIf="alertsService.successMessage$ | async as message">
    <div class="notify__center center">
        <div *ngIf="message.indexOf('|') > 0">{{message | localization}}</div>
        <div *ngIf="message.indexOf('|') <= 0">{{ message }}</div>
        <button class="notify__close"
            (click)="clearMessage()">
            <mat-icon class="mat-icon_close"
                svgIcon="sprite:icon-close"></mat-icon>
        </button>
    </div>
</div>
