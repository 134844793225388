import {MatPaginatorIntl} from '@angular/material/paginator';
import {LocalizationService} from '../localization/localization.service';
import {StringInterpolationService} from '../text-processing/string-interpolation.service';

export function getRussianPaginatorIntl(localizationService: LocalizationService,
                                        interpolationService: StringInterpolationService,
) {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = localizationService.localize('mat_paginator|items_per_page_label');
    paginatorIntl.nextPageLabel = localizationService.localize('mat_paginator|next_page_label');
    paginatorIntl.previousPageLabel = localizationService.localize('mat_paginator|prev_page_label');

    const pageRangeLabelFormat = localizationService.localize('mat_paginator|page_range_label');

    paginatorIntl.getRangeLabel = (page, pageSize, length) => {
        if (length === 0 || pageSize === 0) {
            return interpolationService.format(pageRangeLabelFormat, 0, length);
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return `${startIndex + 1} - ${interpolationService.format(pageRangeLabelFormat, endIndex, length)}`;
    };

    return paginatorIntl;
}
