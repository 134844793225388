import {inject, Injectable} from "@angular/core";
import {HttpBackend, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";

import {GEOAPIFY_API_KEY} from "./injection-tokens";
import {ForwardGeocodeRequest} from "./forward-geocode.request";
import {ReverseGeocodeRequest} from "./reverse-geocode.request";
import {AutocompleteRequest} from "./autocomplete.request";
import {GeocodeResponse} from "./geocode.response";

@Injectable({
    providedIn: "root",
})
export class GeoapifyApiClient {
    private static baseUri = "https://api.geoapify.com/v1/geocode";
    private readonly http = inject(HttpBackend);
    private readonly defaultQueryParams = [`apiKey=${inject(GEOAPIFY_API_KEY)}`, "format=json", "lang=en", "ngsw-bypass=true"];

    public forwardGeocode(request: ForwardGeocodeRequest): Observable<GeocodeResponse> {
        return this.handle(request, "search");
    }

    public reverseGeocode(request: ReverseGeocodeRequest): Observable<GeocodeResponse> {
        return this.handle(request, "reverse");
    }

    public autocomplete(request: AutocompleteRequest): Observable<GeocodeResponse> {
        return this.handle(request, "autocomplete");
    }

    private handle(request: unknown, callMethod: "search" | "reverse" | "autocomplete"): Observable<GeocodeResponse> {
        const baseUri = GeoapifyApiClient.baseUri + `/${callMethod}`;

        const queryParams = Object.keys(request)
            .filter(k => request[k])
            .map(k => `${k}=${encodeURIComponent(request[k])}`);

        const method = "GET";
        const url = baseUri + "?" + this.defaultQueryParams.concat(queryParams).join("&");
        const headers = new HttpHeaders({"Accept": "application/json", "Content-Type": "application/json"});

        const httpRequest = new HttpRequest(method, url, null, {headers: headers});

        return this.http.handle(httpRequest).pipe(
            filter(e => e.type === HttpEventType.Response),
            map(r => (r as HttpResponse<unknown>)?.body as GeocodeResponse));
    }
}
