import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, AppConfig, COUNTRY_DOMAIN} from '../../../infrastructure/app-config/AppConfig';
import {IdentityService} from '../../../core/users/core/identity.service';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {Platform} from '@angular/cdk/platform';
import {UserProfileFacade} from '../../../core/users/core/user-profile.facade';

const baseUrls: { [key in keyof typeof COUNTRY_DOMAIN]: string } = {
    RU: 'https://docs.google.com/forms/d/e/1FAIpQLSedLoMJXpmU8FmLttCR4qVaURYFkazZx5rV8Mky_2VUkttahQ/viewform',
    CA: '/',
    IL: '/',
    UK: '/',
    US: '/',
};

enum GoogleFormParams {
    name,
    id,
    platform,
}

type ParamIdMap = { [formParam in keyof typeof GoogleFormParams]: number };

const paramIds: { [key in keyof typeof COUNTRY_DOMAIN]: ParamIdMap } = {
    RU: {name: 557805449, id: 1428879241, platform: 2022962320},
    CA: {name: 0, id: 0, platform: 0},
    IL: {name: 0, id: 0, platform: 0},
    UK: {name: 0, id: 0, platform: 0},
    US: {name: 0, id: 0, platform: 0},
};

@Injectable({providedIn: 'root'})
export class InterviewGoogleFormUrlService {
    private readonly baseUrl: string;
    private readonly paramIdMap: ParamIdMap;

    constructor(private readonly identityService: IdentityService,
                @Inject(APP_CONFIG) appConfig: AppConfig,
                private readonly platform: Platform,
                private readonly userProfileFacade: UserProfileFacade,
    ) {
        this.baseUrl = baseUrls[appConfig.countryDomain];
        this.paramIdMap = paramIds[appConfig.countryDomain];
    }

    public getUrl(): Observable<string> {
        return this.identityService.stateChanges.pipe(
            take(1),
            map(authState => authState.user),
            switchMap(user => !!user
                ? this.userProfileFacade.getProfile().pipe(map(profile => ({profile, user})))
                : of(null)),
            map(user => {
                if (!user) return null;
                return {
                    name: encodeURIComponent(user.user.DisplayName),
                    id: encodeURIComponent(user.profile.humanReadableId),
                    platform: encodeURIComponent(getPlatformString(this.platform)),
                };
            }),
            map(values => !!values
                ? `${this.baseUrl}?usp=pp_url` +
                Object
                    .keys(values)
                    .map(key => `&entry.${this.paramIdMap[key]}=${values[key]}`)
                    .join('')
                : `${this.baseUrl}?usp=sf_link`),
        );
    }
}

function getPlatformString(platform: Platform) {
    if (platform.IOS) return 'iOS';
    if (platform.ANDROID) return 'Android';
    return 'Web';
}
