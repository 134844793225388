import {MatDialogConfig} from '@angular/material/dialog';

export const notificationsPanelConfig: MatDialogConfig = {
    panelClass: 'cdk-overlay-container_notifications',
    position: {
        top: '64px',
    },
    backdropClass: 'cdk-overlay-backdrop_notifications',
    autoFocus: false,
};
