import {inject, Injectable} from '@angular/core';
import {AppEventsService} from '../../../infrastructure/events/app-events.service';
import {CompletedFeaturesService} from './completed-features.service';
import {distinctUntilChanged, filter, mergeMap} from 'rxjs/operators';
import {ServiceFeatures} from './api';
import {Subject} from 'rxjs';
import {CatalogAppEvents, ReferralsAtLeastOneParticipated} from './catalog-app-events';
import {DvsAppEvents} from "../../dvs/dvs-app-events";

@Injectable({
    providedIn: 'root',
})
export class CompletedFeaturesEventConsumer {
    private readonly appEvents = inject(AppEventsService);
    private readonly api = inject(CompletedFeaturesService);

    private readonly completedSuject$ = new Subject<ServiceFeatures>();

    public subscribe(): void {

        this.completedSuject$
            .pipe(distinctUntilChanged(), mergeMap(f => this.api.complete(f)))
            .subscribe();

        this.appEvents.events$
            .pipe(filter(e => e.eventName === CatalogAppEvents.AudioUploaded))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.AudioRecording));

        this.appEvents.events$
            .pipe(filter(e => e.eventName === CatalogAppEvents.WorkerWorkHistoryRecordAdded))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.WorkHistory))

        this.appEvents.events$
            .pipe(filter(e => e.eventName === CatalogAppEvents.WorkerDataCompleted))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.WorkerData))

        this.appEvents.events$
            .pipe(filter(e => e.eventName === CatalogAppEvents.SuggestionsPageOpened))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.Suggestions));

        this.appEvents.events$
            .pipe(filter(e => e.eventName === CatalogAppEvents.ReferralsAtLeastOneParticipated))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.Referrals));

        this.appEvents.events$
            .pipe(filter(e => e.eventName === CatalogAppEvents.TaxesPageOpened))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.Taxes));

        this.appEvents.events$
            .pipe(filter(e => e.eventName === CatalogAppEvents.MapSearchPageOpened))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.MapSearch));

        this.appEvents.events$
            .pipe(filter(e => e.eventName === DvsAppEvents.DocumentUploaded))
            .subscribe(() => this.completedSuject$.next(ServiceFeatures.UploadDocuments));
    }
}
