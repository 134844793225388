import {Inject, Injectable} from '@angular/core';
import {WINDOW_TOKEN} from '../../../../infrastructure/dom/WINDOW_TOKEN';
import {UserRatingState} from './user-rating.state';

@Injectable({
    providedIn: 'root',
})
export class UserRatingStateService {
    private readonly localStorage: Storage;

    constructor(@Inject(WINDOW_TOKEN) window: Window) {
        this.localStorage = window?.localStorage;
    }

    public getState(userId: string): UserRatingState | null {
        const stateJson = this.localStorage?.getItem(`${userId}-rating`);
        return !stateJson ? null : JSON.parse(stateJson);
    }

    public patchState(userId: string, state: UserRatingState): void {
        if (!this.localStorage) {
            return;
        }
        const oldState = this.getState(userId);
        const newState = {...oldState, ...state};
        const newStateJson = JSON.stringify(newState);
        this.localStorage.setItem(`${userId}-rating`, newStateJson);
    }
}
