import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppEvent} from './app.event';
import {AppEventStoreService} from './app-event-store.service';

@Injectable({providedIn: 'root'})
export class AppEventsService {
    constructor(private readonly appEventsStore: AppEventStoreService) {
    }

    public get events$(): Observable<AppEvent> {
        return this.appEventsStore.eventsSubject$.asObservable();
    }
}
