import {Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter, map, switchMap} from 'rxjs/operators';
import {AlertsService} from '../alerts/alerts.service';
import {of, timer} from 'rxjs';

interface AppData {
    versionCompatibilityIndex: number;
}

@Injectable({providedIn: 'root'})
export class ServiceWorkerForceUpdateService {
    private readonly UPDATE_DELAY = 5 * 1000;
    private readonly forcedUpdateMessage = 'alerts|forced_update';

    constructor(private readonly updates: SwUpdate,
                private readonly alerts: AlertsService) {
    }

    public init() {
        if (!this.updates.isEnabled) return;

        this.updates.versionUpdates.pipe(
            filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
            map(event => {
                const currIndex = (event?.currentVersion?.appData as AppData)?.versionCompatibilityIndex ?? 0;
                const newIndex = (event?.latestVersion?.appData as AppData)?.versionCompatibilityIndex ?? 0;
                return newIndex > currIndex;
            }),
            switchMap(forceUpdate => {
                if (forceUpdate) {
                    this.alerts.notifySuccess(this.forcedUpdateMessage);
                    return timer(this.UPDATE_DELAY).pipe(
                        switchMap(() => this.updates.activateUpdate().then(() => document.location.reload())),
                    );
                }
                return of(null);
            }),
        ).subscribe();
    }
}
