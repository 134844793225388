import {ErrorHandler, Inject, Injectable, NgZone, Optional, PLATFORM_ID} from '@angular/core';
import {APPLICATION_LOGGER, ApplicationLogger} from './ApplicationLogger';
import {reloadPage} from './reloadPage';
import {SentryWrapper} from '../sentry/sentry-wrapper.service';
import {isPlatformBrowser} from '@angular/common';
import {Router} from "@angular/router";

@Injectable()
export class ApplicationErrorHandler extends ErrorHandler {
    constructor(@Inject(APPLICATION_LOGGER) @Optional() private appLogger: ApplicationLogger,
                private sentryWrapper: SentryWrapper,
                @Inject(PLATFORM_ID) private readonly platformId: object,
                private readonly ngZone: NgZone,
                private readonly router: Router) {
        super();
    }

    public override handleError(error: any): void {
        // redirect to error page if error is 4002 (cannot match any routes)
        // Angular router doesn't do it by itself if unknown outlet is used
        if (error?.rejection?.code == 4002) {
            this.ngZone.run(() => void this.router.navigate(['/error']));
            return;
        }

        // reload page on 'load chunk failed error'
        if (isPlatformBrowser(this.platformId) && !!error.message) {
            const regExp = new RegExp(/Loading chunk \d+ failed/, 'gmi');
            if (regExp.test(error.message)) {
                reloadPage();
            }

        }
        if (this.appLogger) {
            const rejection = error && error.rejection;
            if (rejection) {
                this.appLogger.error(
                    'Unhandled Promise rejection:',
                    {
                        rejectionMessage: rejection instanceof Error ? rejection.message : rejection,
                        stack: rejection instanceof Error ? rejection.stack : undefined
                    });
                /*,
                    '; Zone:', (<Zone>error.zone).name, '; Task:', error.task && (<Task>error.task).source,
                    '; Value:', rejection, rejection instanceof Error ? rejection.stack : undefined);*/
            } else {
                this.appLogger.error(error);
            }
        }
        //this.sentryWrapper.captureException(error.originalError || error);
        this.sentryWrapper.handleError(error);
        super.handleError(error);
    }
}
